import React, {useEffect, useState} from 'react';
import {Grid, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Lock, Receipt} from '@material-ui/icons';

import AppDatePicker from '../../../../core/components/inputs/AppDatePicker';
import AppInput from '../../../../core/components/inputs/AppInput/AppMuiInput';
import AppMuiInput from '../../../../core/components/inputs/AppInput/AppMuiInput';
import AppAlertStatic from '../../../../core/components/AppAlertStatic';
import ComponentBuilder from "../../../../core/ComponentBuilder";
import CrmTypedAddressBookListingComboBox from '../../../../crm/components/CrmTypedAddressBookListingComboBox';
import AppMuiCard from "../../../../core/components/cards/AppCard";
import FreightCategoryTypeNames from "../../../../hubs/shipment/FreightCategoryTypeNames";
import AppValidationFailure from "../../../../core/components/AppValidationFailure";
import CrmShipmentCarrierComboBox from "../../../../crm/components/CrmShipmentCarrierComboBox";
import ShipmentDefaultCarrierMcNumbers from "../../../../hubs/shipment/ShipmentDefaultCarrierMcNumbers";
import CrmShippingThirdPartyDropDown from "../../../../crm/components/CrmShippingThirdPartyDropDown";
import AppDropdown from '../../../../core/components/dropdowns/AppDropdown';
import SecureField from '../../permissions/SecureField';
import createMidnightDate from '../../../../core/utils/createMidnightDate';
import LabelOrLink from "../../../../crm/components/cardHelperComponents/LabelOrLink";
import isNewShipment from "../../../../hubs/shipment/actions/modification/isNewShipment";
import {Link} from "react-router-dom";
import HotNote from "./HotNote";
import MultiStopTooltip from "../../../../core/components/Tooltips/MultiStopTooltip/MultiStopTooltip";
import {Alert} from "@material-ui/lab";

const useStyles = makeStyles(theme => ({
    boldField: {
        fontWeight: 'bold',
        color: '#000000',
        '& .MuiInputBase-root': {
            color: '#000000',
            fontWeight: 'bold'
        }
    }
}));

const DetailCard = ({
                        selectedShipmentStatus,
                        selectedFreightCategory,
                        bolDate,
                        estimatedDeliveryDate,
                        pickupTime,
                        deliveryTime,
                        freightCategoryTypes,
                        selectedEquipmentType,
                        customerRep,
                        equipmentTypes,
                        candidateEquipmentTypes,
                        setFieldValue,
                        setFieldValues,
                        selectedCustomer,
                        selectedShipper,
                        selectedConsignee,
                        selectedThirdParty,
                        isMultipleStop,
                        selectedCarrier,
                        getCarrier,
                        getCreditStatus,
                        allowDeliveryDate,
                        lockThirdParty,
                        tallgrassBillingCompany,
                        errors,
                        lockedAssociate,
                        lockedDate,
                        isHardCopyPodRequired,
                        bolNumber,
                        shipment
                    }) => {

    const classes = useStyles();

    const [extDatLoadPostingId, setExtDatLoadPostingId] = useState(null);
    const [extTruckstopPostingId, setExtTruckstopPostingId] = useState(null);
    const [multiStops, setMultiStops] = useState(null);

    useEffect(() => {
        if (bolNumber) {
            window.shell.gateway.getOpenLoad(bolNumber).then((openLoad) => {
                setMultiStops(openLoad.multiStops);
                setExtDatLoadPostingId(openLoad.extDatLoadPostingId);
                setExtTruckstopPostingId(openLoad.extTruckstopPostingId);
            });
        }
    }, [bolNumber]);


    const handleFieldChange = (key) => (e) => {
        setFieldValue(key, e.target.value);
    };

    const handleFreightCategoryChange = async (e, value) => {
        const freightCategory = value;

        const fieldValues = [];

        if (freightCategory) {
            fieldValues.push(['hasFreightCategoryChanged', true]);
            fieldValues.push(['selectedFreightCategory', freightCategory]);
            fieldValues.push(['freightCategoryId', freightCategory.id]);

            const equipmentTypesNominees = equipmentTypes.filter(et => et.freightCategoryId === freightCategory.id);
            fieldValues.push(['candidateEquipmentTypes', equipmentTypesNominees]);

            const equipmentTypeNominee = equipmentTypesNominees.length === 1 ? equipmentTypesNominees[0] : null;

            fieldValues.push(['equipmentType', equipmentTypeNominee && equipmentTypeNominee.id]);
            fieldValues.push(['selectedEquipmentType', equipmentTypeNominee]);

            const isTlCategory = freightCategory.name === FreightCategoryTypeNames.Truckload;
            fieldValues.push(['isTlCategory', isTlCategory]);

            const defaultCarrierName = isTlCategory ? ShipmentDefaultCarrierMcNumbers.Tl : ShipmentDefaultCarrierMcNumbers.LtL;
            const carrier = await getCarrier(defaultCarrierName);
            if (!carrier)
                throw new Error(`Unable to find the carrier with mcNumber [${defaultCarrierName}].`);
            fieldValues.push(['carrierMcNumber', carrier.mcNumber]);
            fieldValues.push(['selectedCarrier', carrier]);

            if (isTlCategory) {
                fieldValues.push(['thirdPartyId', tallgrassBillingCompany.companyId]);
                fieldValues.push(['selectedThirdParty', tallgrassBillingCompany]);
            } else {
                fieldValues.push(['thirdPartyId', null]);
                fieldValues.push(['selectedThirdParty', null]);
            }


        } else {
            fieldValues.push(['selectedFreightCategory', null]);
        }

        setFieldValues(fieldValues);

    };

    const handleEquipmentTypeChange = (e, value) => {
        const equipmentType = value;
        if (equipmentType) {
            setFieldValue('equipmentType', equipmentType.name);
            setFieldValue('selectedEquipmentType', equipmentType);
        } else {

            setFieldValue('shipment.equipmentType', null);
            setFieldValue('selectedEquipmentType', null);
        }
    };

    const handleThirdPartyChange = (thirdParty) => {

        if (thirdParty) {
            setFieldValue('thirdPartyId', thirdParty.companyId);
            setFieldValue('selectedThirdParty', thirdParty);
        } else {

            setFieldValue('thirdPartyId', null);
            setFieldValue('selectedThirdParty', null);
        }
    };

    const filterCustomer = (filter) => {
        return {
            ...filter,
            companyIsDisabled: false,
            companyIsInactive: false
        };
    };

    const filterShipperConsignee = (filter) => {
        return {
            ...filter,
            $or: [
                {companyIsDisabled: false},
                {companyIsDisabled: null}
            ],
            affiliateCompanyId: selectedCustomer.companyId,
            companyIsInactive: false,
            primaryContactId: {
                $ne: null
            }
        };
    };

    const filterCarrier = (filter) => {
        return {
            ...filter,
            freightCategoryId: selectedFreightCategory.id,
            isGoodToGo: 1
        };
    };

    const loadCompanyAutofillFields = async (customer) => {
        try {
            return await window.shell.gateway.getCompanyAutofillFields(customer.companyId);
        } catch (e) {
            console.log(e);
        }
    };

    const handleCustomerChange = async (customer) => {
        const fieldValues = [];
        let autofillFields;
        if (customer) {
            autofillFields = await loadCompanyAutofillFields(customer);

            if (isNewShipment(bolNumber)) {
                const creditStatus = await getCreditStatus(customer.companyId);
                fieldValues.push(['creditStatus', creditStatus]);
            } else {
                fieldValues.push(['creditStatus', null]);
            }

            fieldValues.push(['customerId', customer.companyId]);
            fieldValues.push(['selectedCustomer', customer]);
        } else {
            fieldValues.push(['customerId', null]);
            fieldValues.push(['selectedCustomer', null]);
        }

        fieldValues.push(['shipperId', null]);
        fieldValues.push(['selectedShipper', null]);
        fieldValues.push(['consigneeId', null]);
        fieldValues.push(['selectedConsignee', null]);
        fieldValues.push(['hasCustomerChanged', true]);
        if (autofillFields?.generalNote) fieldValues.push(["note", autofillFields.generalNote]);
        if (autofillFields?.rateConNote) fieldValues.push(["rateConNote", autofillFields.rateConNote]);

        setFieldValues(fieldValues);
    };

    const handleShipperChange = async (shipper) => {
        let autofillFields;
        if (shipper) {
            autofillFields = await loadCompanyAutofillFields(shipper);
            setFieldValue('shipperId', shipper.companyId);
            setFieldValue('selectedShipper', shipper);
        } else {

            setFieldValue('shipperId', null);
            setFieldValue('selectedShipper', null);
        }
        if (autofillFields?.autofillShippingHours && shipper?.shippingHours) setFieldValue("pickupTime", shipper.shippingHours.substring(0, 20));
    };

    const handleConsigneeChange = async (consignee) => {
        let autofillFields;
        if (consignee) {
            autofillFields = await loadCompanyAutofillFields(consignee);
            setFieldValue('consigneeId', consignee.companyId);
            setFieldValue('selectedConsignee', consignee);
        } else {

            setFieldValue('consigneeId', null);
            setFieldValue('selectedConsignee', null);
        }
        if (autofillFields?.autofillReceivingHours && consignee?.receivingHours) setFieldValue("deliveryTime", consignee.receivingHours.substring(0, 20));
    };

    const handleCarrierChange = (carrier) => {
        const fieldValues = [];
        if (carrier) {
            fieldValues.push(['carrierMcNumber', carrier.mcNumber]);
            fieldValues.push(['selectedCarrier', carrier]);
        } else {
            fieldValues.push(['carrierMcNumber', null]);
            fieldValues.push(['selectedCarrier', null]);
        }

        fieldValues.push(['repName', null]);
        fieldValues.push(['repPhone', null]);
        fieldValues.push(['repEmail', null]);
        fieldValues.push(['carrierCallContactPreferred', null]);
        fieldValues.push(['carrierTextContactPreferred', null]);
        fieldValues.push(['carrierEmailContactPreferred', null]);
        fieldValues.push(['experienceNote', null]);
        fieldValues.push(['driverName', null]);
        fieldValues.push(['driverPhone', null]);
        fieldValues.push(['truckNumber', null]);
        fieldValues.push(['trailerNumber', null]);
        fieldValues.push(['driverCallContactPreferred', null]);
        fieldValues.push(['driverTextContactPreferred', null]);

        setFieldValues(fieldValues);
    };

    const handlePickupDateChange = (date) => {
        const bolDate = date && date.isValid() ?
            createMidnightDate(date.toDate()) : null;

        setFieldValue('bolDate', bolDate);
    };

    const handleDeliveryDateChange = (date) => {
        const deliveryDate = date && date.isValid() ?
            createMidnightDate(date.toDate()) : null;
        setFieldValue('estimatedDeliveryDate', deliveryDate);
    };

    return (
        <AppMuiCard>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <AppMuiInput
                                disabled
                                label={'Status'}
                                value={shipment.hasOwnProperty('voidId') ? 'Cancelled/Voided' : selectedShipmentStatus?.name || ''}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SecureField
                                pageName={'details'}
                                fieldName={'freightCategory'}
                                as={AppDropdown}
                                id={'freightCategory'}
                                label={'Freight Category'}
                                field={'name'}
                                fieldIndex={'id'}
                                options={freightCategoryTypes}
                                value={selectedFreightCategory || null}
                                onChange={handleFreightCategoryChange}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.boldField}>
                            <LabelOrLink labelText={"Customer"} selected={selectedCustomer} styles={{color: "black"}} />
                            {selectedCustomer?.hotNote &&
                                <HotNote company={selectedCustomer} />
                            }
                            <SecureField
                                pageName={'details'}
                                fieldName={'customer'}
                                as={CrmTypedAddressBookListingComboBox}
                                types={[CrmTypedAddressBookListingComboBox.Customer]}
                                value={selectedCustomer || null}
                                onChange={handleCustomerChange}
                                filterMap={filterCustomer}
                                popupIcon={' '}
                            />
                            <AppValidationFailure
                                message={errors.selectedCustomer}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <label>Pickup Date</label>
                            <SecureField
                                pageName={'details'}
                                fieldName={'bolDate'}
                                as={AppDatePicker}
                                id={'lockedDate'}
                                value={bolDate}
                                onChange={handlePickupDateChange}
                            />
                            <AppValidationFailure
                                message={errors.bolDate}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SecureField
                                pageName={'details'}
                                fieldName={'pickupTime'}
                                as={AppInput}
                                id={'pickupTime'}
                                label={'Pickup Time'}
                                inputProps={{
                                    maxLength: 20
                                }}
                                value={pickupTime || ''}
                                onChange={handleFieldChange('pickupTime')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {
                                allowDeliveryDate &&
                                <React.Fragment>
                                    <label htmlFor="deliveryDate">Delivery Date</label>
                                    <SecureField
                                        pageName={'details'}
                                        fieldName={'estimatedDeliveryDate'}
                                        as={AppDatePicker}
                                        id={'estimatedDeliveryDate'}
                                        value={estimatedDeliveryDate}
                                        onChange={handleDeliveryDateChange}
                                    />
                                </React.Fragment>
                            }
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SecureField
                                pageName={'details'}
                                fieldName={'deliveryTime'}
                                as={AppInput}
                                id={'pickupTime'}
                                inputProps={{
                                    maxLength: 20
                                }}
                                label={'Delivery Time'}
                                value={deliveryTime || ''}
                                onChange={handleFieldChange('deliveryTime')}
                            />
                        </Grid>
                        {
                            isHardCopyPodRequired &&
                            <Grid item xs={12}>
                                <AppAlertStatic color={'info'} icon={<Receipt />}>
                                    <Typography variant={'body2'}>POD Required for Invoicing.</Typography>
                                </AppAlertStatic>
                            </Grid>
                        }
                        {
                            isMultipleStop &&
                            <Grid item xs={12}>
                                <MultiStopTooltip
                                    shipmentBolNumber={bolNumber}
                                    container={
                                        <Alert
                                            severity={'warning'}
                                        >
                                            <Typography variant={'body2'}>MULTI-STOP LOAD</Typography>
                                        </Alert>
                                    }
                                />
                            </Grid>
                        }
                        {
                            lockedAssociate && lockedDate &&
                            <Grid item xs={12}>
                                <AppAlertStatic color={'info'} icon={<Lock />}>
                                    <Typography variant={'body2'}>Locked
                                        by <strong>{lockedAssociate.firstName} {lockedAssociate.lastName}</strong> on <strong>{lockedDate.toMoment().format('L LT')}</strong>.</Typography>
                                </AppAlertStatic>
                            </Grid>
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <SecureField
                                pageName={'details'}
                                fieldName={'equipmentType'}
                                as={AppDropdown}
                                id={'equipmentType'}
                                label={'Equipment Type'}
                                field={'name'}
                                fieldIndex={'id'}
                                options={candidateEquipmentTypes}
                                value={selectedEquipmentType || null}
                                onChange={handleEquipmentTypeChange}
                            />
                            <AppValidationFailure
                                message={errors.selectedEquipmentType}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <SecureField
                                pageName={'details'}
                                fieldName={'customerRep'}
                                as={AppInput}
                                id={'customerRep'}
                                label={'Customer Rep.'}
                                value={customerRep || ''}
                                inputProps={{
                                    maxLength: 50
                                }}
                                onChange={handleFieldChange('customerRep')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <LabelOrLink labelText={"Shipper"} selected={selectedShipper} styles={{color: '#28a745'}} />
                            {selectedShipper?.hotNote &&
                                <HotNote company={selectedShipper} />
                            }
                            <SecureField
                                pageName={'details'}
                                fieldName={'shipper'}
                                as={CrmTypedAddressBookListingComboBox}
                                style={{width: '100%'}}
                                types={[CrmTypedAddressBookListingComboBox.Customer, CrmTypedAddressBookListingComboBox.ShipperConsignee]}
                                value={selectedShipper || null}
                                onChange={handleShipperChange}
                                placeholder="Search..."
                                filterMap={filterShipperConsignee}
                                popupIcon={' '}
                                disabled={!selectedCustomer}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <LabelOrLink
                                labelText={"Consignee"} selected={selectedConsignee} styles={{color: '#FF0000'}}
                            />
                            {selectedConsignee?.hotNote &&
                                <HotNote company={selectedConsignee} />
                            }
                            <SecureField
                                pageName={'details'}
                                fieldName={'consignee'}
                                as={CrmTypedAddressBookListingComboBox}
                                style={{width: '100%'}}
                                types={[CrmTypedAddressBookListingComboBox.Customer, CrmTypedAddressBookListingComboBox.ShipperConsignee, CrmTypedAddressBookListingComboBox.Customer]}
                                value={selectedConsignee || null}
                                placeholder="Search..."
                                onChange={handleConsigneeChange}
                                filterMap={filterShipperConsignee}
                                popupIcon={' '}
                                disabled={!selectedCustomer}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <LabelOrLink labelText={"Carrier"} selected={selectedCarrier} styles={{color: 'blue'}} />
                            <span style={{display: "block", float: "right"}}>
                {extDatLoadPostingId && <Link
                    to={"/open-loads-board/dat-postings"} target="_blank" style={{color: "blue", margin: 4}}
                > Posted on DAT</Link>}
                                {extTruckstopPostingId && <Link
                                    to={"/open-loads-board/its-postings"} target="_blank"
                                    style={{color: "red", margin: 4}}
                                > Posted on ITS</Link>}
              </span>
                            <SecureField
                                pageName={'details'}
                                fieldName={'carrier'}
                                as={CrmShipmentCarrierComboBox}
                                style={{width: '100%'}}
                                value={selectedCarrier || null}
                                onChange={handleCarrierChange}
                                placeholder="Search..."
                                filterMap={filterCarrier}
                                popupIcon={' '}
                            />
                            <AppValidationFailure
                                message={errors.selectedCarrier}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <SecureField
                                pageName={'details'}
                                fieldName={'thirdPartyId'}
                                as={CrmShippingThirdPartyDropDown}
                                disabled={lockThirdParty}
                                value={selectedThirdParty || null}
                                onChange={handleThirdPartyChange}
                            />
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </AppMuiCard>
    );
};

export default ComponentBuilder
    .wrap(DetailCard)
    .build();
