import React, {forwardRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {editCell, loadDetailsCell, ShipmentNumberCell,} from "../../includes/listingCells/multiUseListingCells";
import {
    callScriptNotesCell,
    coveredCell,
    internalNoteCell,
    mileageItsDatCell,
    postedToCell,
    ShipmentEstMilesHeaderCell,
    ShipmentPostedToHeaderCell
} from "./column-cells/shipmentListingCells";
import DetailsDialog from "../../includes/dialog/DetailsDialog";
import EditDialog from "../../includes/dialog/EditDialog";
import TgfDataGrid from "../../../../core/kendo/dataGrid/TgfDataGrid";
import "./shipmentListing.scss";
import {useDispatch} from "react-redux";
import AgentTooltipComponent from "../../../../crm/components/agent-tooltip/AgentTooltipComponent";
import {LoadFlagsCell} from "../../../../core/kendo/dataGrid/cells/LoadFlags";

const useStyles = makeStyles((theme) => ({
    tdStyles: {
        textDecoration: 'none',
        color: theme.palette.tgfLink.main,
        fontWeight: 'bold',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    coveredCell: {
        color: theme.palette.tgfGreen.main,
        textAlign: 'center'
    },
    centerCells: {
        textAlign: 'center'
    },
    editButton: {
        "&:hover": {
            backgroundColor: "transparent",
            color: '#FFFFFF'
        }
    },
}));

const ShipmentListing = forwardRef(function ShipmentListing(props, ref) {

    const personalDispatch = useDispatch();

    const classes = useStyles();

    const [selectedOpenLoad, setSelectedOpenLoad] = useState(null);
    const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);

    const handleCoveredChange = (openLoadRecord, searchOptions) => {
        personalDispatch(window.shell.actions.sys.processStart('handleCoveredChange'));
        window.shell.gateway.getShipment(openLoadRecord.bolNumber).then((shipment) => {
            shipment.isCovered = !openLoadRecord.isCovered;
            window.shell.gateway.saveShipment(shipment).then(() => {
                props.onCoveredToggle();
            }).finally(() => {
                personalDispatch(window.shell.actions.sys.processComplete('handleCoveredChange'));
            });
        });
    };

    const applyAdditionalFilters = (odata) => {
        odata = odata ?? {
            top: 20,
            skip: 0
        };

        if (props.filterAssociate != null) {
            odata.filter.and = {
                agentId: props.filterAssociate,
            };
        }
        return odata;
    };

    const loadData = async (odata) => {
        const dataPage = await window.shell.gateway.getAllOpenLoads(odata);
        return dataPage;
    };

    const onClickLoadDetails = async (props) => {
        setDetailsDialogOpen(true);
        setSelectedOpenLoad(props.dataItem);
    };

    const onClickEditLoad = async (data) => {
        setEditDialogOpen(true);
        setSelectedOpenLoad(data);
    };

    const GridColumns = [
        {
            title: 'Rep',
            field: 'rep',
            width: 40,
            type: 'custom',
            cell: (props) =>
                <td>
                <span>
                    <AgentTooltipComponent content={props.dataItem.rep} agentId={props.dataItem?.agentId} />
                </span>
                </td>,
        },
        {
            title: 'Ship #',
            field: 'bolNumber',
            width: 60,
            type: 'custom',
            cell: ShipmentNumberCell
        },
        {
            title: 'P/U Date',
            field: 'pickupDate',
            width: 80,
            type: 'date'
        },
        {
            title: 'Customer Name',
            field: 'customerName',
            width: 190,
            type: 'link',
            url: (props) => `/address-book/${props.customerId}/details`,
        },
        {
            title: 'From City',
            field: 'fromCity',
            width: 150,
            type: 'text',
        },
        {
            title: 'From State',
            field: 'fromState',
            width: 50,
            type: 'text',
        },
        {
            title: 'To City',
            field: 'toCity',
            width: 150,
            dataType: 'text',
        },
        {
            title: 'To State',
            field: 'toState',
            width: 50,
            dataType: 'text',
        },
        {
            title: 'Equipment Type',
            field: 'equipmentType',
            width: 160,
            dataType: 'text',
        },
        {
            title: "Load Flags",
            field: '',
            width: 100,
            type: "custom",
            cell: (props) => <LoadFlagsCell checkBolNumber={true} cellProps={props} />,
            sortable: false,
        },
        {
            title: 'Customer Cost',
            field: 'customerCost',
            type: 'currency',
            width: 70,
        },
        {
            title: <ShipmentPostedToHeaderCell />,
            field: 'PostedTo',
            width: 110,
            type: "custom",
            cell: (props) => postedToCell(props),
            sortable: false,
        },
        {
            title: <ShipmentEstMilesHeaderCell />,
            field: '',
            width: 75,
            type: "custom",
            cell: (props) => mileageItsDatCell(props),
            sortable: false,
        },
        {
            title: 'Target Rate',
            field: 'targetRate',
            width: 80,
            type: 'currency',
        },
        {
            title: 'Call Script Notes',
            field: 'callScriptNotes',
            type: "custom",
            cell: (props) => callScriptNotesCell(props),
            sortable: false,
            minWidth: 140,
        },
        {
            title: 'Internal Notes',
            field: 'internalNotes',
            width: 60,
            type: 'custom',
            cell: (props) => internalNoteCell(props),
            sortable: false,
        },
        {
            title: 'Load Details',
            field: ' ',
            width: 50,
            type: 'custom',
            cell: (props) => loadDetailsCell(props, onClickLoadDetails),
            sortable: false,
        },
        {
            title: 'Covered',
            field: 'isCovered',
            width: 60,
            type: 'custom',
            cell: (props) => coveredCell(props, handleCoveredChange, classes),

        },
        {
            title: ' ',
            field: ' ',
            noSort: true,
            type: 'custom',
            width: 40,
            cell: (props) => editCell(props, onClickEditLoad),
            sortable: false,
        },
    ];

    return (
        <>
            <TgfDataGrid
                className={"open-load-board_shipment-listing-grid"}
                ref={ref}
                onLoadDataPage={loadData}
                onApplyAdditionalDataStateAsOdata={applyAdditionalFilters}
                onDataPageLoaded={props.onDataChanged}
                gridColumns={GridColumns}
                sort={[{field: "pickupDate", dir: "asc"}]}
                pageable={{
                    pageSize: 20,
                    buttonCount: 10,
                    pageSizes: [5, 10, 20, 50, 100, 500]
                }}
            />

            {detailsDialogOpen &&
                <DetailsDialog
                    setDetailsDialogOpen={setDetailsDialogOpen}
                    selectedOpenLoad={selectedOpenLoad}
                />
            }
            {editDialogOpen &&
                <EditDialog
                    setEditDialogOpen={setEditDialogOpen}
                    openedFrom={'shipments'}
                    bolNumber={selectedOpenLoad.bolNumber}
                    datLoadPostingId={selectedOpenLoad.datLoadPostingId}
                    truckstopLoadPostingId={selectedOpenLoad.truckstopLoadPostingId}
                    closeDialog={props.onCloseDialog}
                />
            }
        </>
    );
});

export default ShipmentListing;
