import React, {useEffect, useState} from "react";
import AppMuiCard from "../../../core/components/cards/AppCard";
import AppCurrencyText from "../../../core/components/text/AppCurrencyText";
import AppText from "../../../core/components/text/AppText";

const TotalRevenuePaceTable = (props) => {
    const [monthYearValid, setMonthYearValid] = useState(false);
    const [monthBusinessDaysTotal, setMonthBusinessDaysTotal] = useState(0);
    const [monthBusinessDaysToDate, setMonthBusinessDaysToDate] = useState(0);
    const { date, grossMarginData } = props;

    // If selection is not equal to current month, then we return n / a
    useEffect( () => {

        const year = date.getFullYear();
        const month = date.getMonth();
        const isMonthYearValid = date && `${year}${month}` === `${new Date().getFullYear()}${new Date().getMonth()}`;
        setMonthYearValid(isMonthYearValid);

        window.shell.gateway.getTotalBusinessDays(year, month + 1).then((businessDays) => {
            setMonthBusinessDaysTotal(businessDays?.total?.month ?? 1)
            setMonthBusinessDaysToDate(businessDays?.toDate?.month ?? 1)
        });

    }, [date]);

    const getTotalRevenuePace = (marginTotal) => {
        return (marginTotal / monthBusinessDaysToDate) * monthBusinessDaysTotal;
    }

    let totalRevenuePace;
    if(grossMarginData.total)
        totalRevenuePace = getTotalRevenuePace(grossMarginData.total.customerCost);

  return grossMarginData.total ? (
    <AppMuiCard>
      <h1>
        <AppText
          value={" Total Revenue Pace: "}
          style={
            grossMarginData.total.margin < 0
              ? { color: "red" }
              : { color: "blue" }
          }
        ></AppText>
        {monthYearValid ?
          <AppCurrencyText value={totalRevenuePace}></AppCurrencyText>
          :
          <AppCurrencyText value={grossMarginData.total.customerCost}></AppCurrencyText>
        }
      </h1>
    </AppMuiCard>
  ) : (
    <React.Fragment></React.Fragment>
  );
};

export default TotalRevenuePaceTable;
