import React, {useEffect, useState} from "react";
import {MenuItem, Select} from "@material-ui/core";


const BusinessDayDropdown = ({date, value, defaultValue, onChange, disabled = false}) => {

    const [selectedValue, setValue] = useState(value == null ? defaultValue : value);
    const handleChange = (event) => {
        const value = event?.target.value;
        setValue(value);
        if (onChange)
            onChange(date, value);
    };

    useEffect(() => {
        setValue(value == null ? defaultValue : value);
    }, [date, value, defaultValue, disabled]);

    return <Select value={selectedValue} onChange={handleChange} disabled={disabled}>
        <MenuItem value={0}>0</MenuItem>
        <MenuItem value={0.5}>0.5</MenuItem>
        <MenuItem value={1}>1</MenuItem>
    </Select>
};

export default BusinessDayDropdown;