import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import {Tooltip} from '@material-ui/core';

const AppIconButton = ({
                           Icon,
                           tooltip,
                           variant = 'outlined',
                           color = 'primary',
                           size = 'medium',
                           onDarkBackground = false,
                           ...props
                       }) => {

    const useStyles = makeStyles(theme => ({
        button: {
            color: onDarkBackground ? theme.palette.white : theme.palette[color].main,
            borderColor: theme.palette[color].main,
            borderWidth: 1,
            '&:hover': {
                borderColor: theme.palette[color].main,
                backgroundColor: theme.palette[color].main,
                color: theme.palette.white,
                outline: 'none'
            },
            '&:focus': {
                backgroundColor: theme.palette[color].main,
                borderColor: theme.palette[color].main,
                color: theme.palette.white,
                outline: 'none'
            },
        },
        contained: {
            color: theme.palette.white,
            borderColor: theme.palette[color].main,
            backgroundColor: theme.palette[color].main,
            '&:hover': {
                borderColor: theme.palette.white,
                backgroundColor: theme.palette.white,
                color: theme.palette[color].main,
                outline: 'none'
            },
            '&:focus': {
                borderColor: theme.palette.white,
                backgroundColor: theme.palette.white,
                color: theme.palette[color].main,
                outline: 'none'
            },
        },
        icon: {
            color: theme.palette['outlinedWarning'],
            width: 24,
            height: 24,
            display: 'flex',
            alignItems: 'center',
            marginRight: theme.spacing(1)
        },
        active: {
            color: theme.palette.primary.main,
            '& $icon': {
                color: theme.palette.primary.main
            }
        }
    }));
    const classes = useStyles();

    return (
        <IconButton
            variant={variant}
            className={props.className ? props.className : variant === 'contained' ? classes.contained : classes.button} {...props}
            size={size}
        >
            {
                tooltip ?
                    (
                        <Tooltip title={tooltip}>
                            <Icon size={size} />
                        </Tooltip>
                    ) :
                    (
                        <Icon />
                    )
            }
        </IconButton>
    );
};

export default AppIconButton;