import {Grid} from "@material-ui/core";
import AppMuiButton from "../../../../core/components/AppButton";
import {Undo} from "@material-ui/icons";

const BusinessDaysButtonBar = (props) => {
    return (
        <Grid container spacing={2}>
            <Grid item>
                <AppMuiButton
                    type={"button"}
                    color={'primary'}
                    onClick={props.handleSubmit}
                    disabled={!props.isDirty}>
                    Save
                </AppMuiButton>
            </Grid>
            <Grid item>
                <AppMuiButton
                    startIcon={<Undo />}
                    disabled={!props.isDirty}
                    color="warning"
                    onClick={props.handleReset} >
                    Reset
                </AppMuiButton>
            </Grid>
        </Grid>);
}

export default BusinessDaysButtonBar;