import React, {forwardRef, useState} from 'react';
import {
    EstMilesHeaderCell,
    LoadFlagsHeaderCell,
    postAgeCell,
    ShipmentNumberCell,
} from "../../includes/listingCells/multiUseListingCells";
import {
    datCommentsCell,
    datCommodityCell,
    datPickupDeliveryHoursCell,
    fullPartialCell,
} from "./column-cells/datListingCells";
import DetailsDialog from "../../includes/dialog/DetailsDialog";
import EditDialog from "../../includes/dialog/EditDialog";
import useIsAdministrator from "../../../../hubs/personaV2/selectors/useIsAdministrator";
import TgfDataGrid from "../../../../core/kendo/dataGrid/TgfDataGrid";
import AppIconButton from "../../../../core/components/AppButton/AppIconButton";
import {Edit, Info} from "@material-ui/icons";
import {useDispatch, useSelector} from "react-redux";
import AgentTooltipComponent from "../../../../crm/components/agent-tooltip/AgentTooltipComponent";
import {LoadFlagsCell} from "../../../../core/kendo/dataGrid/cells/LoadFlags";

const DatListing = forwardRef(function DatListing(props, ref) {

    const personalDispatch = useDispatch();

    const [selectedOpenLoad, setSelectedOpenLoad] = useState(null);
    const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);

    const isAdmin = useIsAdministrator();
    const hasDatCredentials = useSelector((state) => {
        return state.persona.associate.hasDatCredentials;
    });

    const applyAdditionalFilters = (odata) => {
        odata = odata ?? {
            top: 20,
            skip: 0
        };

        if (props.filterAssociate != null) {
            odata.filter.and = {
                agentId: props.filterAssociate,
            };
        }
        return odata;
    };

    const loadData = async (odata) => {
        const dataPage = await window.shell.gateway.getAllDatPostings(odata);
        return dataPage;
    };

    const onClickLoadDetails = (data) => {
        setDetailsDialogOpen(true);
        setSelectedOpenLoad(data);
    };

    const onClickEditLoad = (data) => {
        setEditDialogOpen(true);
        setSelectedOpenLoad(data);
    };

    // refresh call
    const refreshDatPosting = async (postingId) => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('refresh'));
            await window.shell.gateway.refreshDatPosting(postingId);
            props.onRefreshClick();
        } catch (e) {
            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({
                content: 'Refresh failed',
                color: 'error'
            }));
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('refresh'));
        }
    };

    const editCell = (props, onEditButtonClick) => {
        return <td>
            <AppIconButton
                Icon={Edit}
                tooltip={`Edit Load`}
                onClick={() => onEditButtonClick(props.dataItem)}
            />
        </td>;
    };

    // TODO move this into multi-use-cells when all grids are converted.
    const loadDetailsCell = (props, onClickLoadDetails) => {
        return (
            <td className={"center"}>
                <AppIconButton
                    Icon={Info}
                    tooltip={`Load Details`}
                    onClick={() => onClickLoadDetails(props.dataItem)}
                    disabled={!props.dataItem?.bolNumber}
                />
            </td>
        );
    };

    const columns = [
        {
            field: "user",
            title: "Posted By",
            type: 'custom',
            cell: (props) =>
                <td>
                <span>
                    <AgentTooltipComponent content={props.dataItem.user} agentId={props.dataItem?.userId} />
                </span>
                </td>,
            width: 75
        },
        {
            title: 'Ship #', field: 'bolNumber', type: 'custom',
            cell: ShipmentNumberCell,
            width: 70
        },
        {
            title: 'Refresh In', field: "postLastUpdated", type: 'custom',
            cell: (props) => postAgeCell(props, refreshDatPosting, !hasDatCredentials, 'You currently do not have valid DAT credentials.', 'When this countdown reaches 00:00, the posting can be refreshed by clicking this icon or by updating the posting in some manner.  DAT allows postings to be refreshed only once every 15 minutes.'),
            width: 75,
        },
        {title: 'P/U Date Start', field: 'earlyPickupDate', type: 'dateFromUTCDateTime'},
        {title: 'P/U Date End', field: 'latePickupDate', type: "dateFromUTCDateTime"},
        {title: 'From City', field: 'originCity', type: 'text'},
        {title: 'From State', field: 'originState', type: 'text', width: 55},
        {title: 'To City', field: 'destinationCity', type: 'text'},
        {title: 'To State', field: 'destinationState', type: 'text', width: 55},
        {
            title: "Hours", field: "pickupDeliveryHours", type: 'custom',
            filterable: false,
            sortable: false,
            cell: datPickupDeliveryHoursCell,
            width: 55

        },
        {title: 'Equip. Type', field: 'equipmentCode', type: 'text', width: 70, className: "center"},
        {
            title: <LoadFlagsHeaderCell loadType={"DAT"} />,
            field: '',
            type: "custom",
            cell: (props) => <LoadFlagsCell checkBolNumber={true} cellProps={props} />,
            sortable: false,
            width: 100
        },
        {title: 'Length', field: 'lengthFeet', type: "number", width: 55},
        {title: 'Weight', field: 'weightPounds', type: "number", width: 65},
        {
            title: 'F/P', field: "fullPartial", type: 'custom',
            cell: fullPartialCell,
            width: 45,
        },
        {title: <EstMilesHeaderCell />, field: "estMiles", type: "number", width: 65},
        {title: 'Posted Amt', field: 'postedAmountUsd', type: 'currency', width: 75},
        {
            title: ' ', field: "comments", type: 'custom',
            cell: datCommentsCell,
            sortable: false,
            width: 70
        },
        {
            title: ' ', field: "commodity", type: 'custom',
            cell: datCommodityCell,
            sortable: false,
            width: 75
        },
        {
            title: 'Load Details', field: '', type: 'custom',
            cell: (props) => loadDetailsCell(props, onClickLoadDetails),
            width: 50,
            sortable: false,
        },
        {
            title: ' ', field: '', type: 'custom',
            cell: (props) => editCell(props, onClickEditLoad),
            sortable: false,
            width: 45
        },
    ];

    if (isAdmin) {
        columns.unshift(
            {
                title: 'Rep',
                field: 'rep',
                type: 'custom',
                cell: (props) =>
                    <td>
                <span>
                    <AgentTooltipComponent content={props.dataItem.rep} agentId={props.dataItem?.agentId} />
                </span>
                    </td>,
                width: 75
            }
        );
    }

    return (
        <>
            <TgfDataGrid
                ref={ref}
                onApplyAdditionalDataStateAsOdata={applyAdditionalFilters}
                onLoadDataPage={loadData}
                onDataPageLoaded={props.onDataChanged}
                gridColumns={columns}
                sort={[{field: "latePickupDate", dir: "asc"}]}
                pageable={{
                    pageSize: 20,
                    buttonCount: 10,
                    pageSizes: [5, 10, 20, 50, 100, 500]
                }}
            />
            {detailsDialogOpen &&
                <DetailsDialog
                    setDetailsDialogOpen={setDetailsDialogOpen}
                    selectedOpenLoad={selectedOpenLoad}
                />
            }
            {editDialogOpen &&
                <EditDialog
                    setEditDialogOpen={setEditDialogOpen}
                    openedFrom={'DAT'}
                    bolNumber={selectedOpenLoad.bolNumber}
                    datLoadPostingId={selectedOpenLoad.datLoadPostingId}
                    truckstopLoadPostingId={selectedOpenLoad.truckstopLoadPostingId}
                    closeDialog={props.onCloseDialog}
                />
            }
        </>
    );
});

export default DatListing;
