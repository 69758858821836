import React from 'react';
import AppDenseGrid from '../../../../core/components/AppDenseGrid/AppDenseGrid';
import AppCurrencyText from '../../../../core/components/text/AppCurrencyText';
import AppIconButton from '../../../../core/components/AppButton/AppIconButton';
import {FindInPage as FindInPageIcon} from '@material-ui/icons';
import useIsAdministrator from "../../../../hubs/personaV2/selectors/useIsAdministrator";
import useIsHeadOfAgency from "../../../../hubs/personaV2/selectors/useIsHeadOfAgency";
import {ShipmentHistoryType} from "../../../address-book/ShipmentHistoryPage/includes/ShipmentHistoryType";
import {computeIcon} from "../../../../core/components/ComputeIcon/computeIcon";

const CheckPayoutCell = ({rowData: record}) => {
    if (!record.invoiceActualDeliveryDate)
        return null;

    return (<AppCurrencyText value={record.invoiceEarnedPayout} />);
};

const ShipmentHistoryListing = (props) => {

    const {
        count,
        data = [],
        orderBy,
        order,
        rowsPerPage,
        page,
        onChangePage,
        onChangeRowsPerPage,
        onSort,
        onDocumentQuickViewClick,
        associate,
        historyType
    } = props;

    const isAdmin = useIsAdministrator();
    const isHeadOfAgency = useIsHeadOfAgency();

    const ShipFlagsCell = ({rowData: record}) => {
        const flags = {
            isMultiStop: record.isMultiStop,
            isHazardous: record.isHazMat,
            isBlind: record.isBlind
        };

        const bolNumber = record.shipmentBolNumber;

        return <>
            {bolNumber != null
                ?
                <>
                    <div>
                        {Object.keys(flags).map((key) => (
                            <span style={{padding: 4}}>
                            {computeIcon({
                                flag: key,
                                bool: flags[key],
                                shipmentBolNumber: bolNumber ?? null,
                            })}
                        </span>
                        ))}
                    </div>
                </>
                : <span style={{color: "red"}}>POSTING</span>
            }
        </>;
    };

    const CustomerNameColumn = (historyType) => {
        return {
            historyTypeId: historyType,
            field: 'customerName',
            title: 'Customer',
            dataType: 'component',
            styles: {
                width: 150
            },
            component: AppDenseGrid.Cells
                .LinkCell((record) => [`/address-book/${record.companyId}/details`, record.customerName])
        };
    };
    let GridColumns = [
        {
            field: 'shipmentBolNumber',
            title: 'Ship #',
            dataType: 'component',
            styles: {
                width: 60
            },
            component: AppDenseGrid.Cells
                .LinkCell((record) => [`/shipment/${record.shipmentBolNumber}/details`, record.shipmentBolNumber])
        },
        {
            field: 'shipmentBolDate',
            title: 'Pickup Date',
            dataType: 'date',
            styles: {
                width: 70
            }
        },
        CustomerNameColumn(ShipmentHistoryType.Standard),
        {
            historyTypeId: ShipmentHistoryType.Detailed,
            field: 'freightCategoryType',
            title: 'Type',
            dataType: 'string',
            styles: {
                width: 40
            }
        },
        {
            historyTypeId: ShipmentHistoryType.Detailed,
            field: 'thirdPartyCompanyName',
            title: 'Third Party',
            dataType: 'string',
            styles: {
                width: 120
            }
        },
        {
            field: 'shipmentStatusName',
            title: historyType.id === ShipmentHistoryType.Standard ? 'Status' : 'Shipment Status',
            dataType: 'string',
            styles: {
                width: 100
            }
        },
        {
            historyTypeId: ShipmentHistoryType.Standard,
            field: 'carrierName',
            title: 'Carrier',
            dataType: 'component',
            styles: {
                width: 150
            },
            component: AppDenseGrid.Cells
                .LinkCell((record) => [`/carrier/${record.carrierMcNumber}/details`, record.carrierName])
        },
        CustomerNameColumn(ShipmentHistoryType.Detailed),
        {
            field: 'carrierCost',
            title: 'Carrier Cost',
            dataType: 'currency',
            styles: {
                width: 80
            }
        },
        {
            field: 'customerCost',
            title: 'Cust. Cost',
            dataType: 'currency',
            styles: {
                width: 80
            }
        },
        {
            historyTypeId: ShipmentHistoryType.Standard,
            field: 'invoicePaidDate',
            title: 'Cust. Paid Date',
            dataType: 'date',
            styles: {
                width: 80
            }
        },
        {
            field: 'totalMargin',
            title: 'Total Margin',
            dataType: 'currency',
            styles: {
                width: 80
            }
        },
        {
            historyTypeId: ShipmentHistoryType.Detailed,
            field: 'fromCity',
            title: 'City From',
            dataType: 'string',
            styles: {
                width: 80
            },
        },
        {
            historyTypeId: ShipmentHistoryType.Detailed,
            field: 'fromState',
            title: 'State From',
            dataType: 'string',
            styles: {
                width: 60
            },
        },
        {
            historyTypeId: ShipmentHistoryType.Detailed,
            field: 'toCity',
            title: 'City To',
            dataType: 'string',
            styles: {
                width: 80
            },
        },
        {
            historyTypeId: ShipmentHistoryType.Detailed,
            field: 'toState',
            title: 'State To',
            dataType: 'string',
            styles: {
                width: 50
            },
        },
        {
            historyTypeId: ShipmentHistoryType.Detailed,
            field: '',
            title: 'Ship Flags',
            dataType: 'component',
            styles: {
                width: 100
            },
            component: ShipFlagsCell
        },
        {
            historyTypeId: ShipmentHistoryType.Detailed,
            field: 'equipmentType',
            title: 'Equipment Type',
            dataType: 'string',
            styles: {
                width: 120
            },
        },
        {
            historyTypeId: ShipmentHistoryType.Standard,
            field: 'invoiceActualDeliveryDate',
            title: 'Locked Date',
            dataType: 'date',
            styles: {
                width: 80
            }
        },
        (isAdmin || isHeadOfAgency) && {
            historyTypeId: ShipmentHistoryType.Standard,
            field: 'invoiceEarnedPayout',
            title: 'Payout',
            styles: {
                display: associate.displayPayouts ? '' : 'none',
                width: 80
            },
            dataType: 'component',
            component: CheckPayoutCell
        },
        (isAdmin || isHeadOfAgency) && {
            historyTypeId: ShipmentHistoryType.Standard,
            field: 'commissionPayoutDate',
            title: 'Payout Date',
            dataType: 'date',
            styles: {
                width: 80
            }
        },
        {
            field: '',
            title: '',
            component: ({rowData: shipmentRecord}) => {
                return (
                    <div style={{textAlign: 'center'}}>
                        <AppIconButton
                            Icon={FindInPageIcon} tooltip={'Quick view the shipment\'s documents.'}
                            onClick={() => onDocumentQuickViewClick(shipmentRecord)}
                        />
                    </div>
                );
            },
            styles: {
                width: 40
            },
            dataType: 'component'
        }];

    const rowProps = (props) => props.invoiceDaysPastDue > 0 ? 'warning' : null;

    const getGridColumnsByHistoryType = () => {
        return GridColumns.filter((gc) => gc && (gc.historyTypeId == null || gc.historyTypeId === historyType.id));
    };

    return (
        <AppDenseGrid
            count={count || 0}
            data={data}
            orderBy={orderBy}
            order={order}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onSort={onSort}
            columns={getGridColumnsByHistoryType()}
            rowProps={rowProps}
        />
    );
};

export default ShipmentHistoryListing;
