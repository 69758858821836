import {Box, Grid } from "@material-ui/core";
import React, {useEffect, useState} from "react";
import BusinessDayDropdown from "./BusinessDayDropdown";

import './BusinessDayControl.scss';

const BusinessDaysControl = (props) => {

    const weekdays = [
        { long: 'Sunday', short: 'Sun' },
        { long: 'Monday', short: 'Mon' },
        { long: 'Tuesday', short: 'Tue' },
        { long: 'Wednesday', short: 'Wed' },
        { long: 'Thursday', short: 'Thu' },
        { long: 'Friday', short: 'Fri' },
        { long: 'Saturday', short: 'Sat' },
    ];

    const { givenYear, givenMonth } = props;
    const [dates, setDates] = useState([]);

    // Maximum number/worst case of expected cells in any given calendar month is 42, or 6 (weeks) rows of 7 (weekdays) columns
    //  eg - one worst case is when the first of the month is on a Saturday, and there are 31 days, resulting in 6 weeks in a month.
    const maxRows = 6;
    const maxColumns = weekdays.length;
    const maxCells = maxRows * maxColumns;

    const rows = [...Array(maxRows).keys()];
    const columns = [...Array(maxColumns).keys()];

    useEffect(() => {
        if (givenYear && givenMonth)
            createCalendarDates(givenYear, givenMonth);
    }, [givenYear, givenMonth, props.originalDates]);

    const createCalendarDates = (givenYear, givenMonth) => {

        const dates = [];
        const givenMonthIndex = givenMonth;
        const monthYear = new Date(givenYear, givenMonthIndex, 1);

        // Determine the last day in a month (could be 28, 30, or 31)
        const lastDay = new Date(givenYear, givenMonthIndex + 1, 1);
        lastDay.setHours(-24); // NOTE: setDate(-1) is not always accurate

        const cellOffset = monthYear.getDay();
        const totalDays = lastDay.getDate();

        // Get total days in the given month.
        [...Array(totalDays).keys()].forEach((dayIndex) => dates.push({date: new Date(givenYear, givenMonthIndex, dayIndex + 1 )}));

        // Create offset/placeholders for empty cells in the calendar.
        // Offset the days in the array based on which weekday the first of the month falls on.
        // eg - Oct 1, 2024 is on a Tuesday, so it is offset by 2 cells on the calendar so that the first of the month is rendered in a Tuesday column.
        [...Array(cellOffset).keys()].forEach(() => dates.unshift(undefined));

        // For consistency, offset/fill the remainder of the array with 'undefined' up to the maximum expected cells.
        [...Array(maxCells - dates.length)].forEach(() => dates.push(undefined));

        let row = 0;

        for (var i = 0; i < maxCells; i++) {

            let foundDate = dates[i];
            let column = i % maxColumns;

            if (foundDate != null) {

                const originalDateIndex = findOriginalDate(foundDate.date);
                foundDate['businessDay'] = originalDateIndex > -1
                    ? props.originalDates[originalDateIndex].businessDay
                    : getDefaultDayValue(foundDate.date);

                foundDate['row'] = row;
                foundDate['column'] = column;
            }

            if ((column + 1) === maxColumns)
                row++
        }

        setDates([...dates]);
    };

    const createCalendarCell = (row, column) => {
        const date = getDate(row, column);
        const foundDate = date?.date;
        const foundValue = date?.businessDay;
        const cellData = foundDate
            ? <Box key={column}>
                <Box>{foundDate?.getDate()}</Box>
                <BusinessDayDropdown
                    date={foundDate?.toLocaleDateString()}
                    value={foundValue}
                    defaultValue={getDefaultDayValue(foundDate)}
                    disabled={isWeekend(foundDate?.getDay())}
                    onChange={handleChange} />
            </Box>
            : '';
        return (<Grid item xs={1} className={"business-day-cell"}>{cellData}</Grid>)
    };

    const isWeekend = (day) => day != null && (day === 6 || day === 0);

    const findOriginalDate = (date) => {
        return props.originalDates?.findIndex(d => {
            const thisDate = date.getUTCDate();
            const originalUtcDate = (new Date(d.date)).getUTCDate();
            return thisDate === originalUtcDate;
        }) ?? -1;
    };

    const findDate = (date) => {
        return dates.findIndex(d => d != null && d.date?.toLocaleDateString() === date);
    };

    const getDate = (row, column) => {
        return dates.find((date) => date != null && date.row === row && date.column === column);
    };

    const getDefaultDayValue = (date) => {
        return isWeekend(date.getDay()) ? 0 : 1;
    };

    const getFormattedDates = (dates) => {
        return dates.filter(d => d != undefined).map(d =>{
            const defaultValue = getDefaultDayValue(d.date);
            const date = d.date;
            const day = date.getDate();
            const formattedDay = day < 10 ? `0${day}` : day;
            return {
                date: `${date.getFullYear()}-${date.getMonth() + 1}-${formattedDay}`,
                businessDay: d.businessDay ?? defaultValue
            }
        });
    };

    const isEmptyRow = (row) => {
        return !dates.some((d) => d != null && d.row === row);
    };

    const handleChange = (date, businessDay) => {
        const foundDateIndex = findDate(date);
        dates[foundDateIndex].businessDay = businessDay;
        setDates([...dates]);

        if (props.onChange)
            props.onChange({
                updatedDate: { date: dates[foundDateIndex].date.toLocaleDateString(), businessDay: businessDay },
                dates: getFormattedDates(dates)
            });
    };

    if (givenYear == null || givenMonth == null)
        return <></>;

    return <Box className={"business-day-control-container"}>
        <Grid container>
            {weekdays.map((weekday) =>
                <Grid key={weekday.short} item xs={1} className={"business-day-cell business-day-header"}>{weekday.short}</Grid>
            )}
            {rows.map((row) => {
                return !isEmptyRow(row)
                    ? <Grid key={row} item xs={12} className={"business-day-row"}>
                            <Grid container>
                                {columns.map((column) => createCalendarCell(row, column))}
                            </Grid>
                      </Grid>
                    : <></>;
            })}
        </Grid>
    </Box>
};

export default BusinessDaysControl;