import React, {useEffect, useState} from "react";
import AppIconButton from "./AppIconButton";
import {KeyboardArrowUp} from "@material-ui/icons";

const AppBackToTopButton = (props) => {
    const {
        icon = KeyboardArrowUp,
        color = 'primary',
        scrollRef,
        buttonBottomPosition = 50,
        buttonRightPosition = 80
    } = props;

    const [showButton, setShowButton] = useState(false);

    const refOnWindow = scrollRef.current === window;

    const scrollToPosition = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollTo({
                top: 0,
                behavior: 'smooth' // smooth scrolling
            });
        }
    };

    const handleScrollToTopButtonVisibility = () => {
        scrollRef.current === window ?
            scrollRef.current?.scrollY > 0 ? setShowButton(true) : setShowButton(false)
            :
            scrollRef.current?.scrollTop > 0 ? setShowButton(true) : setShowButton(false);
    };

    useEffect(() => {
        scrollRef.current?.addEventListener("scroll", handleScrollToTopButtonVisibility);
        return () => {
            scrollRef.current?.removeEventListener("scroll", handleScrollToTopButtonVisibility);
        };
    }, []);

    return (
        <>
            {showButton && (
                <AppIconButton
                    Icon={icon}
                    color={color}
                    onClick={scrollToPosition}
                    style={{position: 'fixed', bottom: buttonBottomPosition, right: buttonRightPosition}}
                    {...props}
                />
            )}
        </>
    );
};

export default AppBackToTopButton;