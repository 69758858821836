import React, {forwardRef, useState} from 'react';
import {
    EstMilesHeaderCell,
    loadDetailsCell,
    LoadFlagsHeaderCell,
    postAgeCell,
    ShipmentNumberCell,
} from "../../includes/listingCells/multiUseListingCells";
import DetailsDialog from "../../includes/dialog/DetailsDialog";
import EditDialog from "../../includes/dialog/EditDialog";
import useIsAdministrator from "../../../../hubs/personaV2/selectors/useIsAdministrator";

import {
    commodityCell,
    eqpNdsSpInfoCell,
    loadDimensionsCell,
    loadOptionsCell,
    transportationModeCell
} from "./column-cells/truckstopListingCells";
import AppIconButton from "../../../../core/components/AppButton/AppIconButton";
import {Edit} from "@material-ui/icons";
import TgfDataGrid from "../../../../core/kendo/dataGrid/TgfDataGrid";
import {useDispatch} from "react-redux";
import AgentTooltipComponent from "../../../../crm/components/agent-tooltip/AgentTooltipComponent";
import {LoadFlagsCell} from "../../../../core/kendo/dataGrid/cells/LoadFlags";

const TruckstopListing = forwardRef(function TruckstopListing(props, ref) {

    const personalDispatch = useDispatch();

    const [selectedOpenLoad, setSelectedOpenLoad] = useState(null);
    const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const employeeContext = JSON.parse(localStorage.getItem("employeeContext") ?? null);

    const isAdmin = useIsAdministrator();
    const hasTruckstopCredentials = employeeContext?.claims?.hasTruckstopCredentials;

    const applyAdditionalFilters = (odata) => {
        odata = odata ?? {
            top: 20,
            skip: 0
        };

        if (props.filterAssociate != null) {
            odata.filter.and = {
                agentId: props.filterAssociate,
            };
        }
        return odata;
    };

    const loadData = async (odata) => {
        const dataPage = await window.shell.gateway.getAllTruckstopPostings(odata);
        return dataPage;
    };

    const onClickLoadDetails = (data) => {
        setDetailsDialogOpen(true);
        setSelectedOpenLoad(data.dataItem);
    };

    const onClickEditLoad = (data) => {
        setEditDialogOpen(true);
        setSelectedOpenLoad(data);
    };

    // refresh call
    const refreshTruckstopPosting = async (postingId) => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('refresh'));
            await window.shell.gateway.refreshTruckstopPosting(postingId);
            props.onRefreshClick();
        } catch (e) {
            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({
                content: 'Refresh failed',
                color: 'error'
            }));
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('refresh'));
        }
    };

    const editCell = (props, onEditButtonClick) => {
        return <td>
            <AppIconButton
                Icon={Edit}
                tooltip={`Edit Load`}
                onClick={() => onEditButtonClick(props.dataItem)}
            />
        </td>;
    };


    const columns = [
        {
            title: 'Posted By',
            field: "user",
            type: 'custom',
            cell: (props) =>
                <td>
                <span>
                    <AgentTooltipComponent content={props.dataItem.user} agentId={props.dataItem?.userId} />
                </span>
                </td>,
            width: 75
        },
        {
            title: 'Ship #', field: 'shipmentId', type: 'custom',
            cell: ShipmentNumberCell,
            width: 65
        },
        {
            title: 'Refresh In', field: "truckstopUpdated", type: 'custom',
            cell: (props) => postAgeCell(props, refreshTruckstopPosting, !hasTruckstopCredentials, 'You currently do not have valid ITS credentials.', 'When this countdown reaches 00:00, the posting can be refreshed by clicking this icon.  ITS automatically refreshes postings anytime they are updated in some manner.  Otherwise, ITS allows postings to be refreshed only once every 15 minutes.'),
            width: 75,
        },
        {title: 'P/U Date Start', field: 'pickupDateStart', type: 'dateFromUTCDateTime'},
        {title: 'P/U Date End', field: 'pickupDateEnd', type: 'dateFromUTCDateTime'},
        {title: 'From City', field: 'fromCity', type: 'text'},
        {title: 'From State', field: 'fromState', type: 'text', width: 50},
        {title: 'To City', field: 'toCity', type: 'text'},
        {title: 'To State', field: 'toState', type: 'text', width: 50},
        {title: 'Del. Date Start', field: 'deliveryDateStart', type: 'date'},
        {title: 'Del. Date End', field: 'deliveryDateEnd', type: 'date'},
        {title: 'Equip. Type', field: 'equipmentType', type: 'text', width: 50},
        {title: 'Load Options', field: 'equipmentOptions', type: 'custom', cell: loadOptionsCell, width: 100},
        {
            title: <LoadFlagsHeaderCell loadType={"ITS"} />,
            field: '',
            type: 'custom',
            cell: (props) => <LoadFlagsCell checkBolNumber={true} cellProps={props} />,
            sortable: false,
            width: 100
        },
        {
            title: 'Dims', field: 'loadDimensions', type: 'custom',
            cell: loadDimensionsCell,
            sortable: false,
            width: 40
        },
        {title: 'Weight', field: 'weight', type: 'number', width: 60},
        {
            title: 'F/P', field: "transportationModeId", type: 'custom',
            cell: transportationModeCell,
            width: 30
        },
        {title: <EstMilesHeaderCell />, field: "estMiles", type: "number", width: 60},
        {title: 'Posted Amt', field: 'postedAmount', type: 'currency', width: 70},
        // Posting Views will come in at phase 4
        /* { title: 'Views', field: 'postViews', type:'text', width:50 },*/
        {
            title: ' ', field: "otherEquipmentNeeds", type: 'custom',
            cell: eqpNdsSpInfoCell,
            sortable: false,
            width: 50
        },
        {
            title: ' ', field: "commodity", type: 'custom',
            cell: commodityCell,
            sortable: false,
            width: 50
        },
        {
            title: 'Load Details', field: '', type: 'custom',
            cell: (props) => loadDetailsCell(props, onClickLoadDetails),
            width: 50,
            sortable: false,
        },
        {
            title: ' ', field: '', type: 'custom',
            cell: (props) => editCell(props, onClickEditLoad),
            sortable: false,
            width: 45
        }
    ];


    if (isAdmin) {
        columns.unshift(
            {
                title: 'Rep',
                field: 'rep',
                type: 'custom',
                cell: (props) =>
                    <td>
                <span>
                    <AgentTooltipComponent content={props.dataItem.rep} agentId={props.dataItem?.agentId} />
                </span>
                    </td>,
                width: 75
            }
        );
    }

    return (
        <>
            <TgfDataGrid
                ref={ref}
                onApplyAdditionalDataStateAsOdata={applyAdditionalFilters}
                onLoadDataPage={loadData}
                onDataPageLoaded={props.onDataChanged}
                gridColumns={columns}
                sort={[{field: "pickupDateEnd", dir: "asc"}]}
                pageable={{
                    pageSize: 20,
                    buttonCount: 10,
                    pageSizes: [5, 10, 20, 50, 100, 500]
                }}
            />

            {detailsDialogOpen &&
                <DetailsDialog
                    setDetailsDialogOpen={setDetailsDialogOpen}
                    selectedOpenLoad={selectedOpenLoad}
                />
            }
            {editDialogOpen &&
                <EditDialog
                    setEditDialogOpen={setEditDialogOpen}
                    openedFrom={'ITS'}
                    bolNumber={selectedOpenLoad.bolNumber}
                    datLoadPostingId={selectedOpenLoad.datLoadPostingId}
                    truckstopLoadPostingId={selectedOpenLoad.truckstopLoadPostingId}
                    closeDialog={props.onCloseDialog}
                />
            }
        </>
    );
});

export default TruckstopListing;
