import React, {useRef} from "react";
import {Tooltip} from "@material-ui/core";
import AppBackToTopButton from "../../AppButton/AppBackToTopButton";

const ScrollableTooltip = (props) => {
    const {
        titleContent,
        container,
        setIsHovered,
        maxHeight = 100,
    } = props;

    const scrollRef = useRef(null);

    const tooltipTitle =
        <div
            ref={scrollRef}
            style={{
                fontSize: ".9rem",
                padding: "0px",
                overflowY: "scroll",
                zIndex: 1,
                maxHeight: maxHeight,
            }}
        >
            <div
                style={{padding: 1}}
            >
                {titleContent}
            </div>
            <AppBackToTopButton
                tooltip={'Back to top'}
                scrollRef={scrollRef}
                onDarkBackground={true}
                buttonBottomPosition={22}
                buttonRightPosition={10}
            />
        </div>;


    return (
        <Tooltip
            interactive
            title={tooltipTitle}
            {...props}
        >
            <span onMouseEnter={() => setIsHovered(true)}>
                {container}
            </span>
        </Tooltip>

    );
};

export default ScrollableTooltip;