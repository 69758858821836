import React, {useEffect, useState} from "react";
import {Divider, Fade} from "@material-ui/core";
import ScrollableTooltip from "../ScrollableTooltip/ScrollableTooltip";
import moment from "moment";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {makeStyles} from "@material-ui/core/styles";

const MultiStopTooltip = (props) => {
    const {
        shipmentBolNumber,
        container = <span style={{color: "#0066ff", cursor: "pointer"}}> View</span>
    } = props;

    const useStyles = makeStyles(theme => ({
        divider: {
            backgroundColor: theme.palette.tgfGrey.fieldBorder,
            width: '100%',
        },
    }));
    const classes = useStyles();

    const [multiStops, setMultiStops] = React.useState(null);
    const [isHovered, setIsHovered] = useState(false);

    const loadPageDependencies = async () => {
        try {
            setMultiStops(await window.shell.gateway.getShipmentMultiStops(shipmentBolNumber));
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        if (shipmentBolNumber && isHovered) {
            loadPageDependencies();
        }
    }, [shipmentBolNumber, isHovered]);

    const multiStopTooltipTitle =
        <List>
            {multiStops?.map((stop, index) => {
                return (
                    <>
                        {index !== 0 && (
                            <Divider
                                className={classes.divider}
                            />
                        )}
                        <ListItem key={index}>
                            <div
                                key={`stop-${index}`}
                                className={"tooltip-multiStop"}
                            >
                                <p>
                                    Stop {index + 1}
                                </p>
                                <p>
                                    {stop.city}, {stop.state} {stop.zip}
                                </p>
                                <p>
                                    {stop.stopType} -{' '}
                                    {stop.arrivalDate && moment.utc(stop.arrivalDate).format('l')} -{' '}
                                    {stop.arrivalTime}
                                </p>
                            </div>
                        </ListItem>
                    </>
                );
            })}
        </List>;

    return (
        <ScrollableTooltip
            titleContent={multiStopTooltipTitle}
            container={container}
            setIsHovered={setIsHovered}
            maxHeight={"500px"}
            TransitionComponent={Fade}
            TransitionProps={{timeout: 350}}
            enterDelay={700}
            enterNextDelay={700}
            leaveDelay={200}
            leaveNextDelay={200}
        />
    );
};

export default MultiStopTooltip;