import {
    loadFlagBlindActiveIcon,
    loadFlagBlindInactiveIcon,
    loadFlagHazardousActiveIcon,
    loadFlagHazardousInactiveIcon,
    loadFlagMultistopInactiveIcon,
    loadFlagMutlistopActiveIcon
} from "../../media/icons/exportCustomIcons";
import MultiStopTooltip from "../Tooltips/MultiStopTooltip/MultiStopTooltip";
import React from "react";

export const computeIcon = (props) => {
    const {
        flag,
        bool,
        shipmentBolNumber = null,
    } = props;

    const imageStyle = {height: 19, width: 19};

    let image = <></>;
    switch (flag) {
        case "isMultiStop":
            image = bool ?
                shipmentBolNumber ?
                    <MultiStopTooltip
                        shipmentBolNumber={shipmentBolNumber}
                        container={
                            <img style={imageStyle} src={loadFlagMutlistopActiveIcon} alt={`is multistop`} />
                        }
                    />
                    :
                    <img style={imageStyle} src={loadFlagMutlistopActiveIcon} alt={`is multistop`} />
                :
                <img
                    style={imageStyle}
                    src={loadFlagMultistopInactiveIcon}
                    alt={`is not multistop`}
                />;
            break;
        case "isHazardous":
            image = <img
                style={imageStyle}
                src={bool ? loadFlagHazardousActiveIcon : loadFlagHazardousInactiveIcon}
                alt={`is${bool ? `` : ` not`} hazardous`}
            />;
            break;
        case "isBlind":
            image = <img
                style={imageStyle}
                src={bool ? loadFlagBlindActiveIcon : loadFlagBlindInactiveIcon}
                alt={`is${bool ? `` : ` not`} blind`}
            />;
            break;
        default:
            break;
    }

    return image;
};