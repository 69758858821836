import React from "react";
import AppIconButton from "../../../../core/components/AppButton/AppIconButton";
import {Edit, Info} from "@material-ui/icons";
import {Link} from "react-router-dom";
import PostAge from "./PostAge";
import InfoIconTooltip from "../../../../core/components/Tooltips/InfoIconTooltip/InfoIconTooltip";
import {useSelector} from "react-redux";

export const LoadFlagsHeaderCell = ({loadType}) => {
    return (
        <div style={{display: "flex", alignItems: "end", justifyContent: "space-between"}}>
            <span>Load Flags</span>
            <InfoIconTooltip
                title={`This information is dependent upon how the shipment is built in the CRM and does not reflect how it may be posted in ${loadType}.  If you see "POSTING", it's because there is no shipment involved with the posting.`}
            />
        </div>
    );
};

export const EstMilesHeaderCell = () => {
    return (
        <div style={{display: "flex", alignItems: "end", justifyContent: "space-between"}}>
            <span>Est. Miles</span>
            <InfoIconTooltip
                title={`The miles that are displayed are the approximate driving miles according to Google Maps and are based off the involved From and To cities and states.`}
            />
        </div>
    );
};


// Multi use Cells
export const loadDetailsCell = (props, onClickLoadDetails) => {
    return <td className={props.dataItem.isCovered ? "is-covered" : null}>
        <AppIconButton
            Icon={Info}
            onClick={() => onClickLoadDetails(props)}
            disabled={!props.dataItem?.bolNumber}
        />
    </td>;
};

// TODO we are going to pass in the whole row/record when we click the edit button.
export const editCell = (props, onClickEditButton) => {
    return (
        <td className={props.dataItem.isCovered === 1 ? "is-covered" : null}>
            <AppIconButton
                Icon={Edit}
                tooltip={`Edit Load`}
                onClick={() => onClickEditButton(props.dataItem)}
            />
        </td>
    );
};

export const editUserCell = (props, onClickEditButton) => {
    return (
        <td>
            <AppIconButton
                Icon={Edit}
                tooltip={'Edit Agency User'}
                onClick={() => onClickEditButton(props.dataItem)}
            />
        </td>
    );
};

export const ShipmentNumberCell = (cellProps) => {
    const openLinksInNewTab = useSelector((state) => state.persona.associate.openLinksInNewTab);
    const bolNumber = cellProps.dataItem?.bolNumber;
    return <td>
        {bolNumber
            ? <Link
                className={"tgf-grid-link-cell"} to={`/shipment/${bolNumber}/details`}
                target={openLinksInNewTab ? "_blank" : ""}
            >{bolNumber}</Link>
            : <span style={{color: "red"}}>POSTING</span>
        }
    </td>;
};

export const postAgeCell = (cellProps, refreshPosting, disabled, disabledReason, tooltip) => {
    return <td>
        <PostAge
            dateRefreshable={cellProps.dataItem?.datRefreshable ? new Date(cellProps.dataItem?.datRefreshable) : new Date(cellProps.dataItem?.truckstopRefreshable)}
            postId={cellProps.dataItem?.id}
            refreshPosting={refreshPosting}
            disabled={disabled}
            disabledReason={disabledReason}
            showText={false}
            tooltip={tooltip}
        />
    </td>;
};

