import {computeIcon} from "../../../components/ComputeIcon/computeIcon";
import React from "react";

export const LoadFlagsCell = (props) => {
    const {
        cellProps,
        checkBolNumber = false,
        spanStyling = {padding: 4}
    } = props;

    const flags = {
        isMultiStop: cellProps?.dataItem?.isMultiStop,
        isHazardous: cellProps?.dataItem?.isHazMat,
        isBlind: cellProps?.dataItem?.isBlind
    };

    const bolNumber = cellProps?.dataItem?.bolNumber;

    return (
        <td>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                {checkBolNumber && bolNumber == null ?
                    <span style={{color: "red"}}>POSTING</span>
                    :
                    Object.keys(flags).map((key) => (
                        <span key={key} style={spanStyling}>
                            {computeIcon({
                                flag: key,
                                bool: flags[key],
                                shipmentBolNumber: bolNumber ?? null,
                            })}
                        </span>
                    ))
                }
            </div>
        </td>
    );
};
